import { createElement } from "react";
import styles from "./button.module.scss";
import Components from "@/components/components";
import classnames from "classnames";
import {
  ButtonGroupStoryblok,
  ButtonStoryblok,
} from "@/types/component-types-sb";

interface Props {
  blok: ButtonGroupStoryblok;
  className?: string;
}

const ButtonGroup = (props: Props) => {
  const {
    blok: { buttons, fullWidthOnMobile },
    className,
  } = props;
  const classes = classnames(
    styles.buttonGroup,
    { [styles.fullWidthOnMobile]: fullWidthOnMobile },
    className
  );
  return buttons ? (
    <div className={classes}>
      {buttons.map((blok) => {
        return createElement(Components(blok.component), {
          blok,
          fullWidthOnMobile,
          key: blok._uid,
        });
      })}
    </div>
  ) : null;
};

export default ButtonGroup;
